
<template>
  <div>
    <div>
      <form @submit.prevent="searchKey()" class="filter_year_province">
        <div class="year">
          <vSelect
            class="form-control"
            label="name"
            :options="allYear"
            v-model="yearData"
            :placeholder="translate('menu2.year')"
            @input="getProvince($event)"
          >
          </vSelect>
        </div>
        <div class="province">
          <vSelect
            class="form-control"
            label="name"
            :options="allProvice"
            v-model="province"
            :placeholder="translate('menu2.province')"
          >
          </vSelect>
        </div>
        <div>
          <button type="submit" class="btn btn-success btn_serach_key">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </form>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";
import moment from "moment";
export default {
  props: [],
  components: { Loader, DatePicker, vSelect },
  mounted() {
    let allYearPass = [];
    this.getAllCoffeeSpotYear()
      .then((res) => {
        if (res.data.success) {
          let date = res.data.message;
          date.forEach((element) => {
            let toWord = moment(element.name).format("YYYY");
            toWord.toString();
            allYearPass.push(toWord);
          });
        }
        this.allYear = allYearPass.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
      })
      .catch((error) => {});
    let allProvicePass = [];
    this.getAllCoffeeSpotProvinces()
      .then((res) => {
        if (res.data.success) {
          let date = res.data.message;
          date.forEach((element) => {
            allProvicePass.push(element.name);
          });
        }
        this.allProvice = allProvicePass.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
      })
      .catch((error) => {});
    const additionalOptions = {};
    this.loader = new Loader({
      apiKey: this.apiKey,
      version: this.version,
      ...additionalOptions,
    });
    this.searchKey();
  },
  data() {
    return {
      allProvice: [],
      locationZoomOut: [],
      locationZoomIn: [],
      notYear: 0,
      allYear: [],
      yearData: "",
      province: "",
      filter: {
        year: new Date(),
        province: "",
      },
      errors: {
        year: "",
        province: "",
      },
      clearMaker: false,
      loader: "",
      apiKey: "AIzaSyBFGiFpmCmxhnS0aPaY-P22DYvO_Ua5xOk",
      version: "weekly",
    };
  },
  computed: mapGetters(["allCoffeeProvinces"]),
  watch: {},
  methods: {
    ...mapActions([
      "getAllCoffeeSpot",
      "getAllCoffeeSpotProvinces",
      "getAllCoffeeSpotYear",
    ]),
    getProvince(e) {
      //   this.getAllCoffeeSpotProvinces(e);
    },
    searchKey() {
      this.filter.year = this.yearData;
      if (this.filter.year == "Invalid date") {
        this.filter.year = "";
        this.notYear = 0;
      } else if (!this.filter.year) {
      } else {
        this.notYear = 1;
      }
      this.filter.province = this.province
        ? (this.filter.province = this.province)
        : (this.filter.province = "");


      this.getAllCoffeeSpot(this.filter)
        .then((res) => {
          if (res.data.success) {
            this.locationZoomIn = res.data.message;
            this.locationZoomOut = this.locationZoomIn.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.loca_id === value.loca_id)
            );
            this.loadLocation(this.locationZoomOut);
          }
        })
        .catch((error) => {});
    },

    loadLocation(dataLocations) {
      this.loader.load().then(() => {
        let infowindow = null;
        let map;
        let markers = [];
        let self = this;
        const focusLocation = { lat: 19.137205, lng: 99.910695 };

        map = new google.maps.Map(document.getElementById("map"), {
          zoom: 7.7,
          center: focusLocation,
        });

        addMarker(dataLocations);
        function addMarker(locations) {
          for (let i = 0; i < locations.length; i++) {
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(
                locations[i].lat,
                locations[i].lng
              ),
              map,
            });
            markers.push(marker);
            google.maps.event.addListener(
              marker,
              "click",
              (function (marker, i) {
                return function () {
                  if (infowindow) {
                    infowindow.close();
                  }
                  let toStr;
                  if (self.notYear == 1) {
                    toStr = moment(locations[i].scored_date).format("YYYY");
                    toStr.toString();
                  }

                  infowindow = new google.maps.InfoWindow();
                  let info = `<a  class="cusor_pointer_under_none" href="/variety-info/${locations[i].id}/${locations[i].name}/${toStr}/undefined" ><div class="con_map"><div class="con_map_header"><h3>${locations[i].name}</h3></div><div class="con_map_content"><div class="con_map_content_address">${locations[i].subdistrict} ${locations[i].district} ${locations[i].province} ${locations[i].postcode}</div><div><img class="img_map" src="/img/score/${locations[i].image}"/></div></div><div class="con_map_varieties"><div class="con_map_varieties_box">${self.translate('menu.coffee_spot_information_map')}<i class="fas fa-arrow-alt-circle-right"></i></div></div></div></a>`;
                  info = infowindow.setContent(info);
                  infowindow.open(map, marker);
                  map.setZoom(12);
                  map.setCenter(marker.getPosition());
                };
              })(marker, i)
            );
          }
        }
        map.addListener("zoom_changed", () => {
          if (map.getZoom() == 11) {
            deleteMarkers();
            addMarker(self.locationZoomOut);
          } else if (map.getZoom() == 12) {
            addMarker(self.locationZoomIn);
          }
        });
        function setMapOnAll(map) {
          for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
          }
        }
        function hideMarkers() {
          setMapOnAll(null);
        }

        function deleteMarkers() {
          hideMarkers();
          markers = [];
        }
      });
    },
  },
};
</script>

<style lang="scss">
#map {
  height: 100vh;
}
.cusor_pointer_under_none {
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.con_map {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &_header {
    background: #57acf3;
    color: #fff;
    padding: 10px 16px;
    h3 {
      font-size: 1rem !important;
    }
  }
  &_content {
    display: grid;
    grid-template-columns: 46% 46%;
    gap: 10px;
    padding: 10px 16px 0 16px;
    @media (max-width: 549px) {
      grid-template-columns: 100%;
    }
    &_address {
      color: #000;
      font-size: 1rem;
      @media (max-width: 549px) {
        font-size: 0.7rem;
      }
    }
    div {
      display: flex;
      justify-content: center;
      .img_map {
        max-width: 180px;
      }
    }
  }
  &_varieties {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 16px 20px 16px;
    justify-content: center;
    &_box {
      line-height: 1.4;
      padding: 8px 6px;
      background: #57acf3;
      color: #fff;
      font-size: 1rem;
      border-radius: 4px;
      transition: all 0.3s;
      width: 100%;
      text-align: center;
      @media (max-width: 549px) {
        font-size: 0.7rem;
      }
      &:hover {
        background: #86c0f3;
      }
    }
  }
}
.gm-style .gm-style-iw-c {
  padding: 0px;
  @media (max-width: 386px) {
    max-width: 240px !important;
    width: 240px;
  }
}
.gm-style .gm-style-iw-d {
  overflow: unset !important;
}
.filter_year_province {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 68px;
  left: 50%;
  z-index: 5;
  @media (max-width: 1116px) {
    left: 40%;
  }
  @media (max-width: 919px) {
    left: 32%;
  }
  @media (max-width: 805px) {
    position: unset;
    margin: 10px 0;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  .province {
    width: 13rem;
  }
  .year {
    width: 8rem;
  }
}
.mx-input,
.btn_serach_key {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  height: 40px;
}
.btn-success {
  background: #92b558 !important;
  border-color: #92b558 !important;
}
.gm-ui-hover-effect {
  opacity: 0.6;
  top: -2px !important;
  right: -2px !important;
  background: #0977d3 !important;
  border-bottom-left-radius: 12px !important;
}
</style>

