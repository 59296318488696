
<template>
  <div>
    <section>
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-5">
            <h1>
              {{ translate("menu.manage_coffee_spot") }}
            </h1>
          </div>
          <div class="col-sm-7 d-flex justify-content-end align-items-center">
            <div class="card-tools">
              <div class="input-group input-group-sm">
                <div class="input-group">
                  <button
                    @click.prevent="clearSearch()"
                    class="btn btn-primary"
                    v-if="isInputSearch"
                  >
                    <i class="fas fa-undo"></i>
                  </button>
                  <input
                    type="text"
                    name="table_search"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    :placeholder="translate('menu.search')"
                    v-model="filter.search"
                    v-on:keyup.enter="searchKey()"
                  />

                  <button
                    type="button"
                    @click="searchKey()"
                    class="btn btn-primary"
                  >
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <div class="d-flex">
              <div class="mr-auto change_list">
                <div class="dropdown">
                  <button
                    class="btn btn-default tb_row_drop dropdown-toggle"
                    type="button"
                    id="showRow"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="tb_row_text"
                      >{{ translate("menu2.show") }} {{ filter.listNum }}
                      {{ translate("menu2.row") }}</span
                    >
                    <span class="icon-caret drop_caret"></span>
                  </button>
                  <div
                    class="
                      dropdown-menu dropdown-menu-right dropdown-menu-lg-left
                      show_list_dropdown
                    "
                    aria-labelledby="showRow"
                  >
                    <a class="dropdown-item" @click="changeList(5)">5</a>
                    <a class="dropdown-item" @click="changeList(10)">10</a>
                    <a class="dropdown-item" @click="changeList(50)">50</a>
                    <a class="dropdown-item" @click="changeList(100)">100</a>
                    <a class="dropdown-item" @click="changeList(200)">200</a>
                    <a class="dropdown-item" @click="changeList(1000)">1000</a>
                  </div>
                </div>
              </div>

              <div class="title">
                <button class="btn btn-loc" @click="openModal()">
                  <i class="fas fa-plus"></i>
                  {{ translate("menu.Add") }}
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-bordered table-hover table-striped sortTable"
              >
                <thead>
                  <tr>
                    <td width="5%">
                      <label>{{ translate("menu.No") }}</label>
                    </td>
                    <td width="10%">
                      <label>{{ translate("menu.Name") }}</label>
                    </td>
                    <td width="20%">
                      <label>{{ translate("menu.Image") }}</label>
                    </td>
                    <td width="10%">
                      <label>{{ translate("menu.Location") }}</label>
                    </td>
                    <td width="20%">
                      <label>{{ translate("menu.Address") }}</label>
                    </td>
                    <td width="20%">
                      <label>{{ translate("menu.MASL") }}</label>
                    </td>
                    <td width="5%">
                      <label>{{ translate("menu.Action") }}</label>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in sortedItems()" :key="index">
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ data.name }}
                    </td>
                    <td>
                      <img
                        class="image-thum-nail"
                        :src="
                          data.image
                            ? `/img/score/${data.image}`
                            : `/img/error_img.png`
                        "
                      />
                    </td>
                    <td>
                      {{ data.location }}
                    </td>
                    <td>
                      {{ data.postcode }}, {{ data.province }},
                      {{ data.district }},
                      {{ data.subdistrict }}
                    </td>
                    <td>
                      {{ data.masl }}
                    </td>
                    <td style="display: flex">
                      <button class="btn btn-action" @click="editModal(data)">
                        <i class="fas fa-edit btn-edit"></i>
                      </button>
                      <button class="btn btn-action" @click="del(data)">
                        <i class="fas fa-trash-alt btn-delete"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- pagination -->
            <div class="row">
              <div class="col-md-8">
                <nav>
                  <ul class="pagination">
                    <li
                      class="page-item"
                      :class="{ disabled: !pagination.first_link }"
                    >
                      <a
                        href="#"
                        @click="getData(pagination.first_link)"
                        class="page-link"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: !pagination.prev_link }"
                    >
                      <a
                        href="#"
                        @click="getData(pagination.prev_link)"
                        class="page-link"
                      >
                        <span aria-hidden="true">&#8249;</span>
                      </a>
                    </li>
                    <template
                      v-for="(pageNumber, index) in pagination.last_page"
                    >
                      <li
                        :key="index"
                        v-if="
                          Math.abs(pageNumber - pagination.current_page) < 3 ||
                          pageNumber === pagination.last_page ||
                          pageNumber == 1
                        "
                        class="page-item"
                        :class="{
                          active: pagination.current_page == pageNumber,
                        }"
                      >
                        <a
                          href="#"
                          @click="getData(pagination.path_page + pageNumber)"
                          class="page-link pagi_link"
                          :class="{
                            last:
                              pageNumber == pagination.last_page &&
                              Math.abs(pageNumber - pagination.current_page) >
                                3,
                            first:
                              pageNumber == 1 &&
                              Math.abs(pageNumber - pagination.current_page) >
                                3,
                          }"
                          >{{ pageNumber }}</a
                        >
                      </li>
                    </template>

                    <li
                      class="page-item"
                      :class="{ disabled: !pagination.next_link }"
                    >
                      <a
                        href="#"
                        @click="getData(pagination.next_link)"
                        class="page-link"
                      >
                        <span aria-hidden="true">&#8250;</span>
                      </a>
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: !pagination.last_link }"
                    >
                      <a
                        href="#"
                        @click="getData(pagination.last_link)"
                        class="page-link"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-md-4 d-flex justify-content-end">
                <div>
                  {{ translate("menu2.page") }}: {{ pagination.from_page }} -
                  {{ pagination.to_page }} {{ translate("menu2.total") }}:
                  {{ pagination.total_page }}
                </div>
              </div>
            </div>
            <!-- pagination -->
          </div>
        </div>
      </div>
    </section>
    <CoffeeSpotModal ref="coffeespotmodal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CoffeeSpotModal from "./CoffeeSpotModal.vue";

export default {
  props: [],
  components: { CoffeeSpotModal },
  mounted() {
    this.filter.listNum = this.$cookies.get("coffeespot_listnum");
    this.getData(this.api_url);
  },
  data() {
    return {
      coffeeSpot: [],
      api_url: "/api/coffeespots",
      pagination: {},
      pagi: "",
      filter: {
        pagiurl: "",

        listNum: "",
        search: "",
      },
      isInputSearch: false,
      sort: {
        key: "",
        isAsc: false,
      },
    };
  },
  computed: mapGetters(["allCoffeeSpot"]),
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.search) {
          this.isInputSearch = true;
        } else {
          this.isInputSearch = false;
        }
      },
    },
  },
  methods: {
    ...mapActions(["getCoffeeSpot", "deleteCoffeeSpot"]),
    openModal() {
      this.$refs.coffeespotmodal.openModal();
    },

    del(data) {
      try {
        swal
          .fire({
            title: this.translate("menu.are_you_sure?"),
            // text: this.translate("menu.do_you_really_want_to_delete") + data.name + this.translate("menu.from_coffee_shop_information"),
            text: `${this.translate("menu.do_you_really_want_to_delete")} ${
              data.name
            } ${this.translate("menu.from_coffee_shop_information")}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: this.translate("menu.cancel"),
            confirmButtonText: this.translate("menu.yes_delete"),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.deleteCoffeeSpot(data.id).then((res) => {
                if (res.data.success) {
                  swal.fire(
                    res.data.message,
                    this.translate("menu.your_information_has_been_deleted"),
                    "success"
                  );
                } else {
                  swal.fire(
                    res.data.message,
                    this.translate(
                      "menu.your_information_has_not_been_deleted"
                    ),
                    "error"
                  );
                }
              });
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    editModal(data) {
      this.$refs.coffeespotmodal.editModal(data);
    },
    sortedItems() {
      const list = this.allCoffeeSpot.slice();
      for (let i = 0; i < list.length; i++) {
        list[i].order = this.$getOverallIndex(i, this.pagination);
      }
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key];
          b = b[this.sort.key];

          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1);
        });
      }

      return list;
    },
    getOverallIndex(index) {
      return (
        this.pagination.current_page * this.pagination.per_page -
        this.pagination.per_page +
        index +
        1
      );
    },
    searchKey() {
      this.filter.pagiurl = this.api_url;
      this.getData(this.filter.pagiurl);
    },
    clearSearch() {
      this.filter.search = "";
      this.getData(this.filter.pagiurl);
    },
    changeList(num) {
      this.$cookies.set("coffeespot_listnum", num);
      this.filter.listNum = num;
      this.getData(this.filter.pagiurl);
    },
    getData(pagi) {
      this.filter.pagiurl = pagi || this.api_url;
      this.getCoffeeSpot(this.filter)
        .then((response) => {
          if (response.data.success) {
            this.filter.listNum = response.data.listNum;
            this.pagination = this.$paginate(response.data.message);
          } else {
            swal.fire(
              this.translate("menu2.error"),
              response.data.message,
              "error"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            swal.fire(
              this.translate("menu2.error"),
              error.response.data.message,
              "warning"
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-action {
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 3px;
  .btn-edit {
    color: green;
  }
  .btn-delete {
    color: red;
  }
}
.title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-loc {
  height: 40px;
  text-align: center;
  background: #55b2be;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  color: white;
  font-size: 16px;

  & i {
    margin-right: 9px;
    font-size: 20px;
  }
}

.image-thum-nail {
  max-width: 250px;
  max-height: 300px;
  border-radius: 10px;
}
</style>
