<template>
  <div>
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="modalvariety"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{
                edit
                  ? translate("menu.edit_coffee_mange_variety")
                  : translate("menu.add_coffee_mange_variety")
              }}
            </h5>

            <div class="container-input">
              <div class="box-input w-haft">
                <label class="title-input is-required">
                  {{ translate("menu.farm_name") }}</label
                >
                <vSelect
                  class="form-control"
                  :options="coffeeSpot"
                  label="name"
                  v-model="form.name"
                  :placeholder="translate('menu.farm_name')"
                  @input="selectFarm($event)"
                ></vSelect>
                <div
                  v-show="!form.name || errors.cosp_id"
                  :class="$errorText('cosp_id')"
                >
                  {{ errors.cosp_id }}
                </div>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.location_name")
                }}</label>
                <label class="form-control isReadonly">{{
                  form.location ? form.location : ""
                }}</label>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.latitude")
                }}</label>
                <label class="form-control isReadonly">{{
                  form.lat ? form.lat : ""
                }}</label>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.longitude")
                }}</label>
                <label class="form-control isReadonly">{{
                  form.lng ? form.lng : ""
                }}</label>
              </div>

              <div class="box-input w-haft">
                <label class="title-input is-required">MASL</label>
                <label class="form-control isReadonly">{{
                  form.masl ? form.masl : ""
                }}</label>
              </div>

              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.zipcode")
                }}</label>
                <label class="form-control isReadonly">{{
                  form.postcode ? form.postcode : ""
                }}</label>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.province")
                }}</label>
                <label class="form-control isReadonly">{{
                  form.province ? form.province : ""
                }}</label>
              </div>

              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.district")
                }}</label>
                <label class="form-control isReadonly">{{
                  form.district ? form.district : ""
                }}</label>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.subdistrict")
                }}</label>
                <label class="form-control isReadonly">{{
                  form.subdistrict ? form.subdistrict : ""
                }}</label>
              </div>

              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.variety_name")
                }}</label>
                <vSelect
                  class="form-control"
                  :options="varietiesOption"
                  label="name"
                  v-model="form.variety"
                  :placeholder="translate('menu.variety_name')"
                  @input="selectOption($event, 'variety_name')"
                ></vSelect>
                <div v-show="!form.variety" :class="$errorText('variety')">
                  {{ errors.variety }}
                </div>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.processing")
                }}</label>
                <vSelect
                  class="form-control"
                  :options="processingOption"
                  label="name"
                  v-model="form.process"
                  :placeholder="translate('menu.process')"
                  @input="selectOption($event, 'processing')"
                ></vSelect>

                <div v-show="!form.process" :class="$errorText('process')">
                  {{ errors.process }}
                </div>
              </div>

              <div class="box-input w-haft">
                <label class="title-input">{{ translate("menu.code") }}</label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  class="form-control"
                  :placeholder="translate('menu.code')"
                  v-model="form.code"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="container-btn">
              <div class="box-btn">
                <div class="btn err" @click="closeModal">
                  {{ translate("menu.cancel") }}
                </div>
              </div>
              <div class="box-btn">
                <div class="btn succ" @click="confirmModal(form)">
                  {{ translate("menu.confirm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
var inputData = {
  code: "",
  cosp_id: "",
  created_at: "",
  created_by: "",
  district: "",
  id: "",
  image: "",
  lat: "",
  lng: "",
  loca_id: "",
  location: "",
  masl: "",
  name: "",
  postcode: "",
  proc_id: "",
  process: "",
  province: "",
  subd_id: "",
  subdistrict: "",
  // updated_at: "",
  // updated_by: "",
  // used: "",
  vari_id: "",
  varieties_id: "",
  variety: "",
};
export default {
  props: {},
  components: { vSelect },
  data() {
    return {
      fomat: "",
      form: { ...inputData },
      errors: { ...inputData },
      coffeeSpot: [],
      edit: false,
      varietiesOption: [],
      processingOption: [],
      filter: {
        pagiurl: "/api/processes",
        listNum: 1000,
        search: "",
      },
      api_coffeespots: {
        pagiurl: "/api/coffeespots",
        listNum: 1000,
        search: "",
      },

      api_Varieties: {
        pagiurl: "/api/varieties",
        listNum: 1000,
        search: "",
      },
    };
  },
  mounted() {
    this.getCoffeeSpot(this.api_coffeespots).then((res) => {
      this.coffeeSpot = res.data.message.data;
    });
    this.getVarieties(this.api_Varieties).then((res) => {
      this.varietiesOption = res.message.data;
    });
    this.fetchProcesses(this.filter)
      .then((response) => {
        this.processingOption = response.data.message.data;
      })
      .catch((error) => {
        if (error.response) {
          swal.fire(
            this.translate("menu2.error"),
            error.response.data.message,
            "warning"
          );
        }
      });
  },

  methods: {
    ...mapActions([
      "getCoffeeSpot",
      "crateCoffeespotVarietie",
      "updateCoffeespotVarietie",
      "getVarieties",
      "fetchProcesses",
      "returnOldDataCoffeespotVarietie",
    ]),
    async openModal(data) {
      this.form = null;
      if (data) {
        this.edit = true;
        this.form = data;
        $(this.$refs.modalvariety).modal("show");
      } else {
        this.edit = false;
        this.form = { ...inputData };
        this.errors = { ...inputData };
        $(this.$refs.modalvariety).modal("show");
      }
    },

    closeModal() {
      $(this.$refs.modalvariety).modal("hide");
    },

    confirmModal(data) {
      if (this.edit) {
        this.updateData(data);
      } else {
        this.addNewData(data);
      }
    },
    selectFarm(event) {
      if (event) {
        for (let [key, value] of Object.entries(event)) {
          if (
            [
              "id",
              "name",
              "location",
              "lat",
              "lng",
              "masl",
              "postcode",
              "province",
              "district",
              "subdistrict",
            ].includes(key)
          ) {
            this.form[key] = value;
          }
        }
      } else {
        for (let [key, value] of Object.entries(this.form)) {
          if (
            [
              "id",
              "name",
              "location",
              "lat",
              "lng",
              "masl",
              "postcode",
              "province",
              "district",
              "subdistrict",
            ].includes(key)
          ) {
            this.form[key] = "";
          }
        }
      }
    },
    selectOption(event, name) {
      if (name == "variety_name") {
        if (event) {
          this.form.variety = event.name;
          this.form.vari_id = event.id;
        } else {
          this.form.variety = null;
          this.form.vari_id = null;
        }
      } else {
        if (event) {
          this.form.process = event.name;
          this.form.proc_id = event.id;
        } else {
          this.form.process = null;
          this.form.proc_id = null;
        }
      }
    },
    addNewData(item) {
      let newData = {
        cosp_id: item.id,
        vari_id: item.vari_id,
        proc_id: item.proc_id,
        variety: item.variety,
        process: item.process,
        code: item.code,
      };

      for (const [key, value] of Object.entries(newData)) {
        if (!value) {
          delete newData[key];
        }
      }
      this.crateCoffeespotVarietie(newData).then((response) => {
        if (response.data.success) {
          swal.fire({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          $(this.$refs.modalvariety).modal("hide");
        } else {
          if (this.$errorMsg(response.data.message)) {
            swal.fire({
              title: "error",
              text: response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#92b558",
              confirmButtonText: this.translate("menu.agree"),
            });
          }
          this.$validateMsg(response.data.message);
        }
      });
    },
    updateData(item) {
      let dataEdit = {
        varieties_id: item.varieties_id,
        cosp_id: item.id,
        vari_id: item.vari_id,
        proc_id: item.proc_id,
        variety: item.variety,
        process: item.process,
        code: item.code,
      };
      this.updateCoffeespotVarietie(dataEdit).then((response) => {
        Fire.$emit("fetchupdateCoffeespotVarietie");
        if (response.data.success) {
          swal.fire({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          $(this.$refs.modalvariety).modal("hide");
        } else {
          if (this.$errorMsg(response.data.message)) {
            if (response.data.existed_data) {
              this.returnOldData(
                response.data.existed_data,
                response.data.message
              );
            } else {
              swal.fire({
                title: "error",
                text: response.data.message.cosp_id[0]
                  ? response.data.message.cosp_id[0]
                  : this.translate(
                      "menu.please_check_information_and_try_again"
                    ),
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#92b558",
                confirmButtonText: this.translate("menu.agree"),
              });
            }
          }
          this.$validateMsg(response.data.message);
        }
      });
    },
    returnOldData(data, message) {
      swal
        .fire({
          title: "",
          // text: `DO YOU REALLY WANT TO DELETE ${data.name} FROM POSITION INFORMATION`,
          text: message,
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.translate("menu.cancel"),
          confirmButtonText: this.translate("menu2.yes_return"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.returnOldDataCoffeespotVarietie(data).then((res) => {
              Fire.$emit("fetchupdateCoffeespotVarietie");
              if (res.data.success) {
                $(this.$refs.modalvariety).modal("hide");
                swal.fire("", res.data.message, "success");
              }
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background: #ebebeb;
  padding: 38px 32px;

  .modal-header {
    display: block;
    position: relative;
    border: none;

    .modal-title {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: #000;
    }
    .container-input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        &.w-haft {
          width: 48%;
        }
        .title-input {
          &.is-required {
            &::after {
              margin-left: 1px;
              color: rgb(255, 0, 0);
              content: "*";
            }
          }
        }
        input {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          height: 40px;
          :-webkit-autofill {
            color: #fff !important;
          }
          &:focus {
            outline: none;
          }
          //   &:hover {
          //     cursor: pointer;
          //   }
        }
        select {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          height: 40px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .btn-close-modal {
      border: none;
      position: absolute;
      top: 0px;
      right: 0px;
      background: none;
      margin: 16px;

      i {
        font-size: 20px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background: var(--bg-content-default);
      margin: 8px 0;
    }
    .box-name-lotto {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .container-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .box-btn {
      margin: 8px 18px;

      .btn {
        padding: 8px 12px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        color: #fff;
        font-size: 20px;

        &.succ {
          background: #92b558;
          transition: all 0.2s;
          &:hover {
            background: #4b6324;
            transform: scale(1.1);
          }
        }
        &.err {
          background: #e03737;
          transition: all 0.2s;
          &:hover {
            background: #812121;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .isReadonly {
    background: #ffffff8f;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    height: 39px;
  }
}
</style>
