export const gvar = {
    superadmin: 1,
    admin: 1,
    customer: 2,
    user_role_id: 1,
    admin_password_status: {
        DEFAULT_ADMIN_PASSWORD: 1,
        DEFAULT_ADMIN_PASSWORD_CHANGE: 2
    },
    EchoArr: pEcho() //no need to add in gvar.php
};

function pEcho() {
    let appName = document.querySelector("meta[name='app_name']");
    let app_name;
    if (appName) {
        app_name = appName.getAttribute("content");
    }
    let arr = [window.Echo];
    if (app_name == "ufa1678" || app_name == "gold87vip") {
        arr = [window.Echo, window.SecondEcho];
    }
    return arr;
}

//this file must be the same with gvar.php
