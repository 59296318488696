<template>
  <div>
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="coffeespotmodal"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{
                edit
                  ? translate("menu.edit_coffee_spot_information")
                  : translate("menu.add_coffee_spot_information")
              }}
            </h5>
            <div class="container-input">
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.farm_name")
                }}</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="form-control"
                  :placeholder="translate('menu.farm_name')"
                  v-model="form.name"
                  autocomplete="off"
                />
                <div v-show="!form.name" :class="$errorText('name')">
                  {{ errors.name }}
                </div>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.location_name")
                }}</label>
                <vSelect
                  class="form-control"
                  :options="allLocations"
                  label="name"
                  v-model="form.location"
                  :placeholder="translate('menu.Location')"
                ></vSelect>
                <div :class="$errorText('location')">
                  {{ errors.location }}
                </div>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.latitude")
                }}</label>
                <input
                  type="number"
                  class="form-control"
                  name="lat"
                  :placeholder="translate('menu.lat')"
                  v-model="form.lat"
                  @keyup="checkLangData('lat', form.lat)"
                />

                <div :class="$errorText('lat')">
                  {{ errors.lat }}
                </div>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.longitude")
                }}</label>
                <input
                  type="number"
                  class="form-control"
                  name="lng"
                  :placeholder="translate('menu.lng')"
                  v-model="form.lng"
                  @keyup="checkLangData('lng', form.lng)"
                />

                <div :class="$errorText('lng')">
                  {{ errors.lng }}
                </div>
              </div>

              <div class="box-input">
                <label class="title-input"
                  >MASL ({{ translate("menu.meter") }})</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="masl"
                  placeholder="masl"
                  v-model="form.masl"
                  maxlength="6"
                  oninput="this.value=this.value.replace(/[^-?\d.]/g, '').replace(/(\..*)\./g, '$1').replace(/(\.[\d]{2})./g, '$1');"
                />
                <div v-show="!form.masl" :class="$errorText('masl')">
                  {{ errors.masl }}
                </div>
              </div>

              <div class="box-input w-haft">
                <label class="title-input">{{
                  translate("menu.zipcode")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="postcode"
                  :placeholder="translate('menu.postcode')"
                  v-model="form.postcode"
                  @input="searchData(form.postcode)"
                  oninput="this.value=this.value.replace(/[^-?\d.]/g, '').replace(/(\..*)\./g, '$1').replace(/(\.[\d]{2})./g, '$1');"
                />
                <div v-show="!form.postcode" :class="$errorText('postcode')">
                  {{ errors.postcode }}
                </div>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.province")
                }}</label>
                <vSelect
                  class="form-control"
                  :options="
                    form.postcode ? province_selector : provinceAll_selector
                  "
                  label="name"
                  v-model="form.province"
                  @input="searchProvinces($event)"
                  :placeholder="translate('menu.province')"
                ></vSelect>
                <div v-show="!form.province" :class="$errorText('province')">
                  {{ errors.province }}
                </div>
              </div>
              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.district")
                }}</label>
                <vSelect
                  class="form-control"
                  :options="districts_selector"
                  label="name"
                  v-model="form.district"
                  @input="searchDistrict($event)"
                  :placeholder="translate('menu.district')"
                ></vSelect>
                <div v-show="!form.district" :class="$errorText('district')">
                  {{ errors.district }}
                </div>
              </div>

              <div class="box-input w-haft">
                <label class="title-input is-required">{{
                  translate("menu.subdistrict")
                }}</label>
                <vSelect
                  class="form-control"
                  :options="sub_districts_selector"
                  label="name"
                  v-model="form.subdistrict"
                  @input="searchSubDistrict($event)"
                  :placeholder="translate('menu.subdistrict')"
                ></vSelect>
                <div
                  v-show="!form.subdistrict"
                  :class="$errorText('subdistrict')"
                >
                  {{ errors.subdistrict }}
                </div>
              </div>

              <div class="box-input center">
                <label class="title-input">{{ translate("menu.Image") }}</label>
                <label for="image" style="cursor: pointer">
                  <img
                    id="image_location"
                    :src="
                      imgLocation == '' || imgLocation == null
                        ? '/img/error_img.png'
                        : imgLocation
                    "
                  />
                  <input
                    class="addImg"
                    type="file"
                    id="image"
                    style="display: none"
                    @change="setImg($event)"
                  />
                </label>
              </div>
            </div>
            <div class="container-btn">
              <div class="box-btn">
                <div class="btn err" @click="closeModal">
                  {{ translate("menu.cancel") }}
                </div>
              </div>
              <div class="box-btn">
                <div class="btn succ" @click="confirmed()">
                  {{ translate("menu.confirm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";

var inputData = {
  id: "",
  name: "",
  location: "",
  name: "",
  lat: "",
  lng: "",
  masl: "",
  postcode: "",
  province: "",
  district: "",
  subd_id: "",
  subdistrict: "",
  image: "",
};
export default {
  props: {},
  components: { vSelect },
  data() {
    return {
      edit: false,
      fomat: "",
      form: { ...inputData },
      errors: { ...inputData },
      filter: {
        pagiurl: "/api/locations",
        listNum: 1000,
        search: "",
      },
      messageLat: "",
      messageLng: "",
      imgLocation: "",
    };
  },
  computed: {
    ...mapGetters([
      "allLocations",
      "province_selector",
      "provinceAll_selector",
      "districts_selector",
      "sub_districts_selector",
    ]),
  },
  watch: {
    province_selector() {
      if (this.province_selector.length == 1) {
        this.form.province = this.province_selector[0];
      }
    },
    districts_selector() {
      if (this.districts_selector.length == 1) {
        this.form.district = this.districts_selector[0];
      }
    },
    sub_districts_selector() {
      if (this.sub_districts_selector.length == 1) {
        this.form.subdistrict = this.sub_districts_selector[0];
      }
    },
  },
  mounted() {
    this.getProvinceAllSelector();
    this.fetchLocations(this.filter);
  },

  methods: {
    ...mapActions([
      "fetchLocations",
      "updateCoffeeSpotByid",
      "creatCoffeeSpot",
      "getProvinceAllSelector",
      "getZipCodeSelector",
      "getDistrictsSelector",
      "getSubDistrictsSelector",
      "returnOldDataCoffeeSpot",
    ]),
    async openModal() {
      this.edit = false;
      this.imgLocation = "";
      this.form = { ...inputData };
      this.errors = { ...inputData };
      $(this.$refs.coffeespotmodal).modal("show");
    },

    async editModal(data) {
      this.edit = true;
      this.imgLocation = "";
      this.form = {
        ...data,
      };
      this.imgLocation =
        data.image != "" || data.image != null
          ? `/img/score/${data.image}`
          : "";
      this.getZipCodeSelector(this.form.postcode);
      $(this.$refs.coffeespotmodal).modal("show");
    },

    closeModal() {
      $(this.$refs.coffeespotmodal).modal("hide");
    },
    setImg(event) {
      this.getBase64(event.target.files[0]).then((res) => {
        this.imgLocation = res;
        this.form["image"] = res;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    searchData(postcode) {
      if (postcode) {
        this.getZipCodeSelector(postcode);
      }
    },
    searchProvinces(event) {
      if (event) {
        this.getDistrictsSelector(event.id);
        this.form.prov_id = event.id;
        this.form.province = event.name;
      } else {
        this.form.postcode = null;
        this.form.district = null;
        this.form.subdistrict = null;

        this.form.prov_id = null;
        this.form.province = null;
      }
    },
    searchDistrict(event) {
      if (event) {
        this.getSubDistrictsSelector(event.id);
        this.form.district = event.name;
        this.form.dist_id = event.id;
      } else {
        this.form.district = null;
        this.form.dist_id = null;
      }
    },
    searchSubDistrict(e) {
      if (e) {
        this.form.subd_id = e.id;
        this.form.subdistrict = e.name;
        this.form.postcode = e.zipcode;
      } else {
        this.form.postcode = null;
        this.form.subd_id = null;
        R;
        this.form.subdistrict = null;
      }
    },
    confirmed() {
      try {
        if (this.messageLat || this.messageLng) {
          swal.fire({
            icon: "error",
            title: this.translate("menu.error"),
            text: this.translate(
              "menu.provide_complete_information_please_try_again_please_try_again"
            ),
          });
          return;
        }
        if (!this.edit) {
          let body = {
            name: this.form.name,
            lat: parseFloat(this.form.lat),
            lng: parseFloat(this.form.lng),
            masl: parseFloat(this.form.masl),
            subd_id: this.form.subd_id,
            province: this.form.province.name ?? this.form.province,
            district: this.form.district.name ?? this.form.district,
            subdistrict: this.form.subdistrict,
            postcode: this.form.postcode,
            loca_id: this.form.location.id ?? this.form.loca_id,
            location: this.form.location.name ?? this.form.location,
            image: this.form.image ?? "",
          };
          this.creatCoffeeSpot(body).then((response) => {
            if (response.data.success) {
              $(this.$refs.coffeespotmodal).modal("hide");
              swal.fire({
                icon: "success",
                title: response.data.message,
              });
            } else {
              if (this.$errorMsg(response.data.message)) {
                //****************return deleted data*********************
                if (response.data.existed_data) {
                  this.returnOldData(
                    response.data.existed_data,
                    response.data.message
                  );
                  //****************end of return deleted data***************
                } else {
                  swal.fire(
                    this.translate("menu2.error"),
                    response.data.message,
                    "error"
                  );
                }
              }
              this.$validateMsg(response.data.message);
            }
          });
        } else {
          let body = {
            id: this.form.id,
            name: this.form.name,
            lat: parseFloat(this.form.lat),
            lng: parseFloat(this.form.lng),
            masl: parseFloat(this.form.masl),
            subd_id: this.form.subd_id,
            province: this.form.province.name ?? this.form.province,
            district: this.form.district.name ?? this.form.district,
            subdistrict: this.form.subdistrict.name ?? this.form.subdistrict,
            postcode: this.form.postcode,
            loca_id: this.form.location.id ?? this.form.loca_id,
            location: this.form.location.name ?? this.form.location,
            image: this.form.image ?? "",
          };
          this.updateCoffeeSpotByid(body).then((response) => {
            if (response.data.success) {
              $(this.$refs.coffeespotmodal).modal("hide");
              swal.fire({
                icon: "success",
                title: response.data.message,
              });
            } else {
              if (this.$errorMsg(response.data.message)) {
                //****************return deleted data*********************
                if (response.data.existed_data) {
                  this.returnOldData(
                    response.data.existed_data,
                    response.data.message
                  );
                  //****************end of return deleted data***************
                } else if (
                  response.data.message ==
                  "Latitude and Longitude cannot be the same as another spot."
                ) {
                  let error = {
                    lat: ["The latitude has already been taken."],
                    lng: ["The longitude has already been taken."],
                  };
                  this.$validateMsg(error);
                  swal.fire({
                    title: this.translate("menu.error"),
                    text: response.data.message,
                    icon: "warning",
                    showCancelButton: false,
                  });
                } else {
                  swal.fire({
                    title: this.translate("menu.error"),
                    text: this.translate("check_information_please_try_again"),
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#92b558",
                  });
                }
              }
              if (
                response.data.message !=
                "Latitude and Longitude cannot be the same as another spot."
              ) {
                this.$validateMsg(response.data.message);
              }
            }
          });
        }
      } catch (e) {
        swal.fire({
          icon: "error",
          title: this.translate("menu.incorrect_information"),
          text: this.translate("menu.please_check_information_and_try_again"),
        });
        console.log(e);
      }
    },
    checkLangData(type, data) {
      if (type == "lat") {
        if (data >= 100) {
          this.messageLat = this.translate(
            "menu.incorrec_information_please_check_the_correctness"
          );
        } else {
          this.messageLat = "";
        }
      } else {
        if (data >= 181 || data <= -181) {
          this.messageLng = this.messageLat = this.translate(
            "menu.incorrec_information_please_check_the_correctness"
          );
        } else {
          this.messageLng = "";
        }
      }
    },
    returnOldData(data, message) {
      swal
        .fire({
          title: "",
          // text: `DO YOU REALLY WANT TO DELETE ${data.name} FROM POSITION INFORMATION`,
          text: message,
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.translate("menu.cancel"),
          confirmButtonText: this.translate("menu2.yes_return"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            let form = {
              id: data.id,
            };
            this.returnOldDataCoffeeSpot(form).then((res) => {
              if (res.data.success) {
                $(this.$refs.coffeespotmodal).modal("hide");
                swal.fire("", res.data.message, "success");
              }
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background: #ebebeb;
  padding: 38px 32px;

  .modal-header {
    display: block;
    position: relative;
    border: none;

    .modal-title {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: #000;
    }
    .container-input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        &.w-haft {
          width: 48%;
        }
        &.center {
          align-items: center;
        }
        .title-input {
          &.is-required {
            &::after {
              margin-left: 1px;
              color: rgb(255, 0, 0);
              content: "*";
            }
          }
        }
        input {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          height: 40px;
          :-webkit-autofill {
            color: #fff !important;
          }
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: text;
          }
        }
        .v-select {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          height: 40px;
          &:hover {
            cursor: pointer;
          }
          .vs__dropdown-toggle {
            padding: 0;
            border: 0px solid rgba(60, 60, 60, 0.26);
            border-radius: 0px;
          }
        }
      }
    }
    .btn-close-modal {
      border: none;
      position: absolute;
      top: 0px;
      right: 0px;
      background: none;
      margin: 16px;

      i {
        font-size: 20px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background: var(--bg-content-default);
      margin: 8px 0;
    }
    .box-name-lotto {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .container-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .box-btn {
      margin: 8px 18px;

      .btn {
        padding: 8px 12px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        color: #fff;
        font-size: 20px;

        &.succ {
          background: #92b558;
          transition: all 0.2s;
          &:hover {
            background: #4b6324;
            transform: scale(1.1);
          }
        }
        &.err {
          background: #e03737;
          transition: all 0.2s;
          &:hover {
            background: #812121;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .error-msg {
    font-size: 16px;
    color: red;
    margin-top: 1%;
    margin-bottom: -2%;
  }
}
img {
  z-index: 2;
  pointer-events: none;
  border-radius: 5px;
}
#image_location {
  max-width: 300px;
  max-height: 300px;
  @media (max-width: 490px) {
    max-width: 100%;
  }
}
.addImg {
  z-index: 1;
  margin-top: 23px;
  position: absolute;
  content: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  &:hover {
    transition-duration: 500ms;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }
}
</style>
